<template>
  <div>
    <Loading v-show="loading"></Loading>
    <floorplan
      v-if="userData.image_src !== undefined"
      id="exportMapDiv"
      :img-src="require(`@/assets${userData.image_src}`)"
      :image-width="userData.image_witdh"
      :image-height="userData.image_height"
      :block-data="userData.blockData"
      :entrance-data="userData.entranceData"
      :arrow-data="userData.arrowData"
    >
    </floorplan>
    <br>
    <!-- <div>
      <v-btn
      color="primary"
      dark
      @click="exportMaptoImage"
      style="text-transform: none"
    >
      Export Image
      <v-icon
        dark
        right
      >
        {{ icons.mdiFileExportOutline }}
      </v-icon>
    </v-btn>
    </div> -->
    <br>
  </div>
</template>

<script>
import { mdiDownloadOutline, mdiFileExportOutline } from '@mdi/js'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import combinedStoreModule from './combinedStoreModule'
import Loading from '@/views/components/load/Loading.vue'
import html2canvas from 'html2canvas'
import { isEmpty, getSessionData } from '@core/utils'
import floorplan from '@/views/components/blueprint/FloorPlan.vue'
import eventBus from '@core/utils/eventBus'

const USER_APP_STORE_MODULE_NAME = 'app-combined'

const userData = ref([])
const currentDate = new Date()

export default {
  components: {
    floorplan,
    Loading,
  },
  created() {
    const startDate = sessionStorage.getItem('startDate')
    const endDate = sessionStorage.getItem('endDate')
    this.searchOperation(new Date(startDate), new Date(endDate))
  },
  mounted() {
    eventBus.$on('peopleCountSearch', () => {
      const startDate = sessionStorage.getItem('startDate')
      const endDate = sessionStorage.getItem('endDate')
      this.searchOperation(new Date(startDate), new Date(endDate))
    })

    // 日付選択コンポーネントを表示させる
    this.$dateRangePickerDisplayFlag = true
    this.$selectScDisplayFlag = true
    this.$selectDomesticScDisplayFlag = false
    this.$domesticDatePickerDisplayFlag = false

    this.$datePickerDisplayFlag = false
  },
  beforeDestroy() {
    // eventBus リスナーの削除
    eventBus.$off('peopleCountSearch')
  },
  methods: {
    // 検索処理を行う
    searchOperation(startDate, endDate) {
      const startDateFormatted = `${startDate.getFullYear()}
      -${(startDate.getMonth() + 1).toString().padStart(2, '0')}
      -${startDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      const endDateFormatted = `${endDate.getFullYear()}
      -${(endDate.getMonth() + 1).toString().padStart(2, '0')}
      -${endDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      const siteId = String(getSessionData('data').siteId)
      const searchStartDate = startDateFormatted
      const searchEndDate = endDateFormatted
      this.loading = true
      store
        .dispatch('app-combined/fetchData', {
          params: {
            siteId,
            searchStartDate,
            searchEndDate,
          },
        })
        .then(response => {
          userData.value = response.data.result.data
          this.loading = true
        })
        .catch(error => {
          this.loading = false
          console.log(error)
          this.$router.push({ name: 'error-login' })
        })
    },

    /* map印刷 */
    exportMaptoImage: () => {
      html2canvas(document.querySelector('#exportMapDiv')).then(canvas => {
        const downloadEle = document.createElement('a')
        downloadEle.href = canvas.toDataURL('image/png')
        downloadEle.download = `Combined_${currentDate.toLocaleString()}.jpeg`
        downloadEle.click()
      })
    },
  },
  setup() {
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, combinedStoreModule)
    }

    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    return {
      userData,
      icons: {
        mdiDownloadOutline,
        mdiFileExportOutline,
      },
      isEmpty,
      loading: false,
    }
  },
}
</script>

<style>
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
}
.small {
  margin: 0 auto;
  height: 400px;
}
.table-header-cell-class-1F {
  background: #ffbbff !important;
  color: #000 !important;
}
.table-header-cell-class-4F {
  background: #64fcfc !important;
  color: #000 !important;
}

.table-header-cell-class-BF {
  background: #b384ff !important;
  color: #000 !important;
}

.table-header-cell-class-Total {
  background: #ffff88 !important;
  color: #000 !important;
  text-align: center !important;
}

.table-header-cell-class-CumulativeTotal {
  background: #ffff88 !important;
  color: #000 !important;
  text-align: center !important;
}
.table-header-cell-class-Ratio {
  background: #ffff88 !important;
  color: #000 !important;
}

.table-footer-cell-class-total {
  background: #ffff88 !important;
  color: #000 !important;
}
.table-header-cell-class-Comment {
  background: #ccffcc !important;
  color: #000 !important;
  text-align: center !important;
}

.table-header-cell-class-LastYear {
  background: #ffff88 !important;
  color: #000 !important;
}

.table-header-cell-class-text-align-center {
  text-align: center !important;
}

.table-header-cell-class-table3 {
  background: #ccffcc !important;
  text-align: center !important;
}

.table-body-cell-Sat {
  background: rgb(153, 204, 254) !important;
  color: #000 !important;
}
.table-body-cell-Sat-firstColumn {
  background: rgb(153, 204, 254) !important;
  color: #000 !important;
  padding-left: 8px !important;
}
.table-body-cell-Sun {
  background: rgb(255, 153, 204) !important;
  color: #000 !important;
}

.table-body-cell-Sun-firstColumn {
  background: rgb(255, 153, 204) !important;
  color: #000 !important;
  padding-left: 8px !important;
}

.table-body-cell-Holiday {
  background: #fedcbd !important;
  color: #000 !important;
}

.table-body-cell-Holiday-firstColumn {
  background: #fedcbd !important;
  color: #000 !important;
  padding-left: 8px !important;
}

.table-body-cell-pl8 {
  padding-left: 8px !important;
}
</style>
